#root {
  width: 100vw;
}

/* button {
  font-family: "poppins", "bold" !important;
  font-size: initial !important;
} */

body {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-expand {
  width: 100%;
}

* {
  font-family: "Lato", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: white;
}

.btn-standard {
  background-color: #ff3366;
  color: white;
}

.btn-standard:hover {
  background-color: #fc285d;
  color: white;
}

.btn-lightgray {
  background-color: #f0f3f5;
  color: black;
}

.btn-lightgray:hover {
  background-color: #edeff0;
  color: black;
}

.StripeElement {
  height: 38px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.StripeElement--focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.icons-homepage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 185px;
  height: 100px;
  place-self: center;
  gap: 15px !important;
}

.icons {
  width: 32px;
  height: 32px;
}

.chooseLanguage {
  color: white;
  background-color: transparent;
  width: 230px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 40px;
  cursor: pointer;
  padding: 0px 20px 0px 10px;
  border: none;
  font-weight: italic;
}
.oneItemPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 480px;
}
.onlineMenu {
  width: 100vw;
  max-width: 480px;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  margin-top: -10vh;
  /* padding-bottom: 5vh; - Maybe it will need to be returned */
}

.transition-container {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.transition-container.open {
  max-height: 100%; /* Set to a high value to ensure all content fits */
  opacity: 1;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.parentCategoryName {
  position: absolute;
  top: 8vh;
  text-align: -webkit-center;
  max-width: 100vw;
  word-wrap: break-word;
}

.itemName {
  max-width: 140px;
  font-size: 15px;
  margin-top: 10px;
  text-align: left !important;
  line-height: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.modalItemDescription::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modalItemDescription {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modalItemDescription {
  margin: 0px;
  padding-bottom: 10px;
  line-height: 20px;
  word-break: break-word;
}
.modalItemDescription p {
  margin: 0px;
}
.itemPrice {
  position: absolute;
  right: 10px;
  bottom: 4px;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.itemModal {
  backdrop-filter: blur(5px);
  background-color: transparent;
}

.testoverlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity for the desired level of blur */
  backdrop-filter: blur(5px); /* Adjust the blur amount */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  justify-content: center !important;
}
.oneitem-root {
  overflow: hidden;
  min-height: 40vh;
  max-height: 40vh;
}
.item-img {
  display: block;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}

.oneitem-content {
  margin: 10px 20px 15px 20px;

  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  max-height: 17vh;
  gap: 5px;
}

.cancel-icon {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.oneitem-content-without-desc {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  gap: 5px;
}

.modalBody {
  margin: 0px;
  padding: 0;
  background-color: transparent !important;
  min-height: 50vh;
}
.modal-content {
  background-color: transparent;
  border: none;
}

.oneitem {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.modalItemName {
  font-size: 18px;
  line-height: 20px;
  word-break: break-word;
}

.homeMenu {
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
  gap: 30px;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100vw;
  max-width: 480px;
}

.orderStatusPage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100vw;
  max-width: 480px;
}
.languages {
  display: grid;
  row-gap: 15px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.testLink {
  border: 2px solid;
}
.oneCategory {
  max-width: 400px;
  width: 90vw;
  height: 100px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 2px solid;
}

.break-all {
  line-break: anywhere;
  font-size: 13px;
}

.oneSubCategory {
  max-width: 400px;
  width: 90vw;
  height: 100px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 2px solid white;
}

.nav-link {
  padding: 0;
}
.nova {
  padding-right: 0px !important;
}

.priority {
  margin-bottom: 10px !important;
}

.languageIcons {
  pointer-events: none;
}

.loyBHj img {
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
}
.container {
  margin: 0px !important;
}
.col-lg-8 {
  padding: 0px;
}
.categoryButton {
  border: none;
  box-shadow: 0px 0px 3px;
  border-radius: 10px;
  width: 100px;
  cursor: pointer;
}

.ac-label {
  font-weight: 700;
  position: relative;
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
  display: block;
  cursor: pointer;
  background-color: whiteSmoke;
  transition: background-color 0.15s ease-in-out;
}

.ac-input:checked + label,
.ac-label:hover {
  background-color: #999;
}

.ac-label:after,
.ac-input:checked + .ac-label:after {
  content: "+";
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 2em;
  height: 100%;
  line-height: 2.25em;
  text-align: center;
  background-color: #e5e5e5;
  transition: background-color 0.15s ease-in-out;
}

.ac-label:hover:after,
.ac-input:checked + .ac-label:after {
  background-color: #b5b5b5;
}

.ac-input:checked + .ac-label:after {
  content: "-";
}

.ac-input {
  display: none;
}

.ac-text,
.ac-sub-text {
  opacity: 0;
  height: 0;
  margin-bottom: 0.5em;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}

.ac-input:checked ~ .ac-text,
.ac-sub .ac-input:checked ~ .ac-sub-text {
  opacity: 1;
  height: auto;
}

.ac-sub .ac-label {
  background: none;
  font-weight: 600;
  padding: 0.5em 2em;
  margin-bottom: 0;
}

.ac-sub .ac-label:checked {
  background: none;
  border-bottom: 1px solid whitesmoke;
}

.ac-sub .ac-label:after,
.ac-sub .ac-input:checked + .ac-label:after {
  left: 0;
  background: none;
}

.ac-sub .ac-input:checked + label,
.ac-sub .ac-label:hover {
  background: none;
}

.ac-sub-text {
  padding: 0 1em 0 2em;
}

.order-div {
  background-color: #555555;
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 55px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  justify-content: space-between;
}

.quantity-number {
  position: fixed;
  bottom: 15px;
  right: 80px;
  border: 1px solid white;
  border-radius: 50%;
}

.prikazi-narudzbu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-cart-div {
  width: 80px;
  display: flex;
  gap: 3px;
  height: 30px;
  align-self: center;
}

.quantity-div {
  display: flex;
  place-items: center;
}

.quantity-text {
  display: flex;
  font-size: 11px;
  border: 1px solid white;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 7px;
}

.cart-div {
  width: 30px;
  height: 30px;
}

.order-button {
  background-color: transparent;
  position: absolute;
  right: 12vw;
  top: 10px;
  margin: 0 !important;
  padding: 0 !important;
}

.order-button-modal {
  background-color: transparent;
  margin: 0 !important;
  padding: 0 !important;
}

.order-button-modal:hover {
  background-color: transparent;
}

.order-button:hover {
  background-color: transparent;
}

.variants-div {
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
}

.checkIcon {
  width: 25px !important;
  height: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeVariantButton {
  background-color: #14e714;
  padding: 15px 12px;
  border: 1px solid #14e714;
  border-radius: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: white;
  -webkit-text-fill-color: white;
}

.variantButton {
  background-color: #ffffff;
  padding: 15px 12px;
  border: 1px solid white;
  border-radius: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  -webkit-text-fill-color: black;
}
.blueTextRemoved {
  -webkit-text-fill-color: black;
}

.potvrdiButton {
  position: absolute;
  left: 50%;
  color: white;
  width: 50%;
  transform: translate(-50%, 0%);
  padding: 10px 20px;
  background-color: black;
  border: 1px solid black;
  border-radius: 12px;
}

.activeButton {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: red;
}

.orderNapomenaDiv {
  display: flex;
  gap: 100px;
}

.napomenaText {
  margin: 0px;
  max-width: 300px;
}

.napomenaDiv {
  min-width: 300px;
}

.kolicina-ikone {
  width: 40px;
  height: 40px;
}

.orderTime {
  top: -11px;
  right: 10px;
}

.inputNapomenaDiv {
  width: 300px;
}

.testiranjeKategorija {
  max-width: 400px;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 350px) {
  .inputNapomenaDiv {
    width: 220px;
  }

  .itemPrice {
    font-size: 11px !important;
  }

  .kolicina-ikone {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 400px) {
  .arrowLeft {
    width: 28px !important;
  }

  .itemName {
    max-width: 100px;
    font-size: 15px;
    margin-top: 10px;
    text-align: left !important;
    line-height: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  .napomenaDiv {
    min-width: 0px !important;
    order: 2;
  }

  .orderNapomenaDiv {
    display: grid;
    gap: 10px;
    margin-left: 20px;
  }

  .napomenaHeading {
    font-size: 16px;
    font-weight: bold !important;
    text-align: center;
  }

  .napomenaText {
    max-width: 100px !important;
    font-size: 12px;
    max-height: 100px;
    overflow: scroll;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 2000px) {
  .arrowLeft {
    width: 36px !important;
  }
  .item-img {
    border-radius: 0px;
  }
  .cancel-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .oneitem-root {
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
  }
}

@media only screen and (min-device-width: 540px) and (max-device-width: 1200px) {
  .order-button {
    background-color: transparent;
    position: absolute;
    right: 7vw;
    top: 10px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .order-div {
    background-color: #555555;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 55px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: space-between;
    max-width: 480px;
  }
}

@media only screen and (min-device-width: 1200px) {
  .order-button {
    background-color: transparent;
    position: absolute;
    right: 60px;
    top: 10px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .order-div {
    background-color: #555555;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 55px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: space-between;
    max-width: 480px;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 1280px) {
  /* Styles */

  .modal-content {
    min-width: 240px;
    place-content: center;
  }
  .categoryButton {
    border: none;
    box-shadow: 0px 0px 3px;
    border-radius: 10px;
    font: -webkit-mini-control;
    cursor: pointer;
  }

  .oneitem-root {
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    height: 300px;
    overflow: hidden;
  }
}
.ac-label {
  background-color: transparent;
  display: grid;
  place-items: center;
  padding: 0px !important;
}
.ac-label::after {
  display: none;
}
.ac-label:hover {
  background-color: transparent;
}
.ac-input:checked + label,
.ac-label:hover {
  background-color: transparent;
}
.ac-label:hover:after,
.ac-input:checked + .ac-label:after {
  display: none;
}

.truncate {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
select {
  color: black;
}

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-2px, -2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(2px, 2px);
  }
}

.shake {
  animation: shake 0.3s; /* Adjust the animation duration as needed */
}
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* Modal */

.ads-modal {
  z-index: 10;
  position: absolute;
  width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
  height: 90dvh;
  border-radius: 35px;
}

.close-ads {
  background-color: white !important;
  border-radius: 50%;
  top: 15px;
  position: absolute;
  right: 15px;
  font-size: 28px;
  font-weight: bold;
  width: 30px;
  border: black 1px solid;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
}

.close-ads:hover,
.close-ads:focus {
  background-color: gray;
}

/* Reduce size of ad for tabled and desktop */
@media only screen and (min-device-width: 480px) {
  .ads-modal {
    width: 430px !important;
  }
}

